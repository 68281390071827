import React from 'react';
import { Row, Col, Typography, Image, Collapse } from "antd";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import ShareBlog from '../shareBlog';
import ShareMidButton from '../ShareMidButton';
import {
    Helmet
} from 'react-helmet';
import { BreadCrumb } from '../../ui-elements/index';

const { Title } = Typography;
const { Panel } = Collapse;

const DeepSeekAI = () => {
    const title = "Impact of DeepSeek AI on Global Tech Markets | Mkaits Technologies";
    const description = "Explore the Impact of DeepSeek AI on Global Tech Markets, GPT vs DeepSeek AI, Power shifts and Future Implications in Tech world.";
    const image = "https://www.mkaits.com/assets/deepseekcover.jpg";
    const url = "https://www.mkaits.com/blogs/latest-news/impact-of-deepseek-ai-on-global-tech-markets";
    // Set up scroll behavior on component mount and clean up on unmount
    React.useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={url} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image" content={image} />

            </Helmet>
            <BreadCrumb title='Blogs' description='Your Business Partner' pageName="Impact of DeepSeek AI on Global Tech Markets" breadcrumb={[{ to: '/blogs/latest-news', name: 'Blogs' }]} />
            <div className="py-5 px-lg-5 px-md-5 px-sm-0">
                <div className=''>
                    <ShareBlog url={"/blogs/latest-news/impact-of-deepseek-ai-on-global-tech-markets"} />
                </div>
                <Row>
                    <Col xs={0} sm={0} md={0} lg={6} className="toc-container px-3 my-3 h-50">
                        {/* Links for scrolling */}
                        <h2 className="text-black fw-bold">Table of Contents</h2>
                        <div className='px-2 mx-2 border-black'>
                            {/* <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                A Glimpse into the Future with Mkaits
                            </Link>
                            <br />
                            <br /> */}
                            <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                Advantages of DeepSeek versus GPT models
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                DeepSeek’s Impact on Global Tech Markets and US
                            </Link>
                            <br />
                            <br />
                            <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                The future of AI and Global Market Disruptions
                            </Link>
                        </div>
                    </Col>
                    <Collapse accordion className="d-md-none w-100 mx-3 position-sticky z_i">
                        <Panel header="Table of Contents" key="1" className='py-2 ' >
                            <div className='d-flex flex-column gap-3 border-black mx-3 px-3'>
                                {/* <Link activeClass="activeTab" to="section1" spy={true} smooth={true} duration={50}>
                                    A Glimpse into the Future with Mkaits
                                </Link> */}
                                <Link activeClass="activeTab" to="section2" spy={true} smooth={true} duration={50}>
                                    Advantages of DeepSeek versus GPT models
                                </Link>
                                <Link activeClass="activeTab" to="section3" spy={true} smooth={true} duration={50}>
                                    DeepSeek’s Impact on Global Tech Markets and US
                                </Link>
                                <Link activeClass="activeTab" to="section4" spy={true} smooth={true} duration={50}>
                                    The future of AI and Global Market Disruptions
                                </Link>
                            </div>

                        </Panel>
                    </Collapse>
                    <Col xs={24} sm={24} md={24} lg={14} className="content px-3 my-3">
                        <Title className='text-black mb-1 fs-1'>
                            Impact of DeepSeek AI on Global Tech Markets
                        </Title>
                        <br />
                        <p>The rise of DeepSeek AI has changed the dynamics of global tech markets, going beyond the boundaries of what artificial intelligence achieved in previous years. A rising AI research company called DeepSeek, attracted tech world by launching DeepSeek R1 model. DeepSeek R1 challenges GPT-4 by providing better natural language processing capabilities, superior reasoning and increased efficiency. This has led to deep deliberations about how it is challenging the dominating AI agents and a potential to change AI market.</p>
                        <p>DeepSeek R1 is unique in a way that it is able to produce highly accurate, human-like responses with high processing efficiency. The market shows growing interest toward its capabilities since they have potential to boost AI adoption in industries for automatic content creation, enterprise solutions and AI-powered support operations. Due to rapid growth of DeepSeek AI, from business automation to the future of AI-powered applications, the world has entered into a new transformation where everything is possible with artificial intelligence.</p>
                        <br />
                        <Element name="section1" className="element">
                        </Element>
                        <Element name="section2" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                Advantages of DeepSeek versus GPT models
                            </Title>
                            <br />
                            <p>Deepseek AI has several advantages over existing models such as GPT open-AI series. One of the most important one is that it is relatively very cheap. Previously, it costed huge amount of money to train advances AI models. For instance it took approximately $400 million to train Open ALI GPT-04. Comparatively, the expenditure on DeepSeek AI was under $6 million! The exceptional efficiency of DeepSeek stems from its innovative strategy that requires basic hardware while optimizing various training process phases. This decreases the operational expenditures leading to greater accessibility to businesses and research institutions of all sizes at reasonable costs.  </p>
                            <p>Secondly, when it comes to performance, DeepSeek AI gives a tough time to established AI systems. DeepSeek-R1 matches the performance outcomes of OpenAI's most recent releases when solving mathematical problems, programming and natural language understanding tasks. This is a huge achievement considering the constraints on China due to international sanctions which limits the access to high end computing resources. </p>
                            <br />
                        </Element>
                        <div className='custom-width-sm custom-width-md-lg m-auto'>
                            <Image src="/assets/deepseek1.png" alt="Deep Seek" className='img-fluid w-100' preview={false} />
                        </div>
                        <br />
                        <Element name="section3" className="element">
                            <Title className='text-black mb-1 fs-4'>
                                DeepSeek’s Impact on Global Tech Markets and US
                            </Title>
                            <br />
                            <p>The emergence of DeepSeek AI has an immense impact on US as well as global tech markets. Soon after launching its AI Assistant powered by the DeepSeek-V3 model, the application gained the top spot as the top-rated free app on the U.S Apple App Store surpassing other AI models. This rapid growth led to AI-related stocks experiencing shocking declines. The stock values decreased by up to 5.5% of major big companies such as NVidia, Broadcom, and Super Micro. Overall, US tech sector faced cumulative loss of over $1 trillion in market capitalization. <a href="https://coinedition.com/chinas-deepseek-ai-wipes-out-1-trillion-from-us-crypto-and-tech-markets/" target="_blank" rel="noopener noreferrer" className='text-primary text-decoration-underline'>https://coinedition.com/chinas-deepseek-ai-wipes-out-1-trillion-from-us-crypto-and-tech-markets/</a></p>
                            <br />
                            <div className='custom-width-sm custom-width-md-lg m-auto'>
                                <Image src="/assets/deepseek2.png" alt="Data Analytics" className='img-fluid w-100' preview={false} />
                            </div>
                            <br />
                            <p>Across the globe, mixed responses can be seen. Chinese and Hongkong tech stocks have flourished due to DeepSeek’s innovations. For example, the Heng Seng tech index shows an increase of 2%. On the other hand Japanese chip manufacturers and US tech giants faced huge declines due to DeepSeek’s cost effective models. This divide represents the power shift of global tech landscape, as emerging players like DeepSeek gives a tough competition to the longstanding dominance of established U.S. companies.</p>
                            <br />
                        </Element>
                        <Element name="section4" className="element">
                            <Title className='text-black mb-1 fs-1'>
                                The future of AI and Global Market Disruptions
                            </Title>
                            <Title className='text-black mb-1 fs-4'>
                                Future Implications of AI Market Disruption
                            </Title>
                            <br />
                            <p>One of the essential implications is the developments made by DeepSeek AI which lead to substantial changes within the global AI market. As companies and states compete for latest AI models, we may see changes in regulatory frameworks, heated discussions and rising tensions in competing countries. DeepSeek has initiated an AI market disruption affecting major technology companies which result in future transformation of industries such as finance and healthcare. </p>
                            <br />
                            <Title className='text-black mb-1 fs-4'>
                                Challenges and Opportunities in Businesses
                            </Title>
                            <br />
                            <p>Due to the rise of DeepSeek AI, International businesses faced both the challenges and advantages. Companies who are dependent on AI automation may need to adapt to changes occurring globally. Traditional dominant tech giants may have to decrease their costs and increase efficiency to stay in competition. On the other hand, new emerging businesses and rising rivals are likely to flourish. This kind of transformation would lead to democratization of AI. Where all sizes of businesses can enhance their productivity and optimize customer experiences. </p>
                            <br />
                            <Title className='text-black mb-1 fs-4'>
                                A more Competitive and Decentralized AI Future
                            </Title>
                            <br />
                            <p>Consequently, DeepSeek AI gives both challenges and opportunities to global tech landscape. Its rapid growth shows that AI advancements has broken free from western corporate control creating a decentralized AI environment that drives maximum competition. As AI adoption accelerates, companies and policymakers must navigate an evolving digital landscape where innovation, regulation, and market dynamics will shape the next phase of global technological progress. </p>
                            <br />
                        </Element>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center mt-3'>
                    <ShareMidButton url={"/blogs/latest-news/impact-of-deepseek-ai-on-global-tech-markets"} />
                </div>
            </div>
        </>
    );
}

export default DeepSeekAI;
